import React, { useEffect, useState } from 'react';
import '../components/assets/css/userList.css';
import OrderDataTable from 'react-data-table-component';
import SearchBar from './SearchBar';
import useClipboard from 'react-use-clipboard';
import { CSVLink } from 'react-csv';
import AddUserModal from './modals/AddUserModal';
import axios from 'axios';
import { CKEditor } from '@ckeditor/ckeditor5-react';
const config = require('../Helpers/config.json');
const CreateCategory = () => {
  const [modalShown, toggleModal] = useState(false);
  const [UpdatemodalShown, UpdatetoggleModal] = useState(false);
  const [CreateBookmodalShown, CreateBooktoggleModal] = useState(false);
  const [load, setload] = useState(false);
  const [data, setData] = useState([]);
  const [name, setname] = useState('');
  const [file, setFile] = useState('');
  const [BookFile, setBookFile] = useState('');
  const [BookTitle, setBookTitle] = useState('');
  const [ids, setId] = useState(null);
  const [category_id, setcategory_id] = useState(null);
  useEffect(() => {
    setload(true);
    fetch(`${config['baseUrl']}/getcategories`, {
      method: 'GET',
      headers: { 'content-type': 'application/json' },
    })
      .then((res) => {
        return res.json();
      })
      .then((ress) => {
        setload(false);
        var temp;
        temp = ress.data;
        setData([...temp]);
        console.log(data);
      });
  }, []);
  const deleteCategory = (id) => {
    fetch(`${config['baseUrl']}/deletecategories`, {
      method: 'POST',
      headers: { 'content-type': 'application/json' },
      body: JSON.stringify({
        id: id,
      }),
    })
      .then((res) => {
        return res.json();
      })
      .then((ress) => {
        window.location.reload(true);
      });
  };

  const updateCategory = () => {
    const formData = new FormData();
    formData.append('id', ids);
    formData.append('file', file);
    formData.append('name', name);
    axios
      .post(`${config['baseUrl']}/updatecategories`, formData)
      .then((res) => {
        window.location.reload(true);
        console.log(res.result);
      })
      .catch((error) => {
        console.log('error', error);
      });
  };
  const CreateBook = (id) => {
    const formData = new FormData();
    formData.append('category_id', category_id);
    formData.append('title', BookTitle);
    formData.append('file', BookFile);
    axios
      .post(`${config['baseUrl']}/booksInsert`, formData)
      .then((res) => {
        window.location.reload(true);
        console.log(res.result);
      })
      .catch((error) => {
        console.log('error', error);
      });
  };
  const [isCopied, setCopied] = useClipboard(JSON.stringify(data));
  const countPerPage = 5;

  const columns = [
    {
      name: 'R.No',
      cell: (row, index) => index + 1,
    },
    {
      name: 'Name',
      selector: (row) => <span>{row.name}</span>,
    },
    {
      name: 'Image',
      selector: (row) => (
        <img
          src={`${config['baseUrl']}${row.image}`}
          className='category-img'
        />
      ),
    },
    {
      name: 'Actions',
      cell: (row) => (
        <div className='actionsBtns'>
          <button
            onClick={() => {
              UpdateCategoryModal();
              setId(row.id);
            }}
            className='upadteBtn'>
            Update
          </button>
          <button onClick={() => deleteCategory(row.id)} className='deleteBtn'>
            Delete
          </button>
          <button
            onClick={() => {
              setcategory_id(row.id);
              CreateBooktoggleModal(true);
            }}
            className='updatePwdBtn'>
            Create Book
          </button>
        </div>
      ),
    },
  ];
  const addUser = () => {
    toggleModal(!modalShown);
    console.log(modalShown, 'modal');
  };
  const UpdateCategoryModal = () => {
    UpdatetoggleModal(true);
    console.log(UpdatemodalShown, 'modal');
    console.log(ids, 'iddd');
  };
  return (
    <>
      <div className='userListBox mt-5 mb-5'>
        <div className='innerUserListBox'>
          <div className='btnBox'>
            <button className='addUserBtn' onClick={addUser}>
              Add Category
            </button>
            {/* <button onClick={setCopied}> Copy</button>
                        <CSVLink data={Data} filename={"LeadFile.csv"}>CSV</CSVLink>
                        <button>Excel</button>
                        <button>PDF</button>
                        <button>Print</button> */}
          </div>
          {/* <SearchBar /> */}
        </div>
        <OrderDataTable columns={columns} data={data} highlightOnHover />
      </div>
      {UpdatemodalShown === true ? (
        <div className='addUserModalBox'>
          <div className='addUserBox'>
            <div>
              <div className='inneraddUserBox'>
                <div className='form-group'>
                  <h4>Update Category!</h4>
                  <label>Category name</label>
                  <input
                    onChange={(e) => setname(e.target.value)}
                    type='text'
                    className='form-control'
                    placeholder='Name here!'
                    required
                  />
                </div>
              </div>
              <div className='inneraddUserBox'>
                <div className='form-group'>
                  <label>Category image</label>
                  <input
                    type='file'
                    accept='image/*'
                    onChange={(e) => setFile(e.target.files[0])}
                  />
                </div>
              </div>
              <div className='addUserBtnBox'>
                <button onClick={() => updateCategory()}>Submit</button>
                <button onClick={() => UpdatetoggleModal(false)}>Cancel</button>
              </div>
            </div>
          </div>
        </div>
      ) : null}
      {CreateBookmodalShown === true ? (
        <div className='addUserModalBox'>
          <div className='addUserBox'>
            <div>
              <div className='inneraddUserBox'>
                <div className='form-group'>
                  <h4>Create Book</h4>
                  <label>Book Title</label>
                  <input
                    onChange={(e) => setBookTitle(e.target.value)}
                    type='text'
                    className='form-control'
                    placeholder='Name here!'
                    required
                  />
                </div>
              </div>
              <div className='inneraddUserBox'>
                <div className='form-group'>
                  <label>Book image</label>
                  <input
                    type='file'
                    accept='image/*'
                    onChange={(e) => setBookFile(e.target.files[0])}
                  />
                </div>
              </div>
              <div className='addUserBtnBox'>
                <button onClick={() => CreateBook()}>Submit</button>
                <button onClick={() => CreateBooktoggleModal(false)}>
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </div>
      ) : null}

      <AddUserModal
        close={() => {
          toggleModal(false);
        }}
        {...{ modalShown }}
      />
    </>
  );
};

export default CreateCategory;

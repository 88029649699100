import React, { useEffect, useState } from 'react';
import '../components/assets/css/userList.css';
import OrderDataTable from 'react-data-table-component';
import SearchBar from './SearchBar';
import useClipboard from 'react-use-clipboard';
import { CSVLink } from 'react-csv';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import axios from 'axios';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { RxCross2 } from 'react-icons/rx';
import Spinner from 'react-bootstrap/Spinner';
import Form from 'react-bootstrap/Form';

const config = require('../Helpers/config.json');
const CreateCategory = () => {
  const [showAllPage, setShowAllPages] = useState([]);
  const [updatePage, setUpdatePage] = useState({});
  const [selectedbookId, setSelectedbookId] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoading2, setIsLoading2] = useState(false);

  const [UpdatemodalShown, UpdatetoggleModal] = useState(false);
  const [CreateBookmodalShown, CreateBooktoggleModal] = useState(false);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [selectedLanguageModal, setSelectedLanguageModal] = useState(false);
  const [language, setLanguage] = useState('');
  const [deleteBookId, setDeleteBookId] = useState(null);

  const [load, setload] = useState(false);
  const [data, setData] = useState([]);
  const [BookContentFile, setBookContentFile] = useState('');
  const [BookContent, setBookContent] = useState('');
  const [selectLanguages, setSelectLanguages] = useState({
    chinese: false,
    french: false,
    german: false,
    hindi: false,
    urdu: false,
  });
  const [BookId, setBookId] = useState(null);
  const [show, setShow] = useState(false);
  const [seconds, setSeconds] = useState(150);

  const minutes = Math.floor(seconds / 60);
  const remainingSeconds = seconds % 60;

  useEffect(() => {
    const timer = setInterval(() => {
      setSeconds((prevSeconds) => prevSeconds - 1);
    }, 1000);

    return () => clearInterval(timer);
  }, []);

  useEffect(() => {
    setload(true);
    setShowAllPages([]);
    getAllData();
  }, []);

  const getAllData = async () => {
    await axios.get(`${config['baseUrl']}/getbooks`).then(({ data }) => {
      console.log(data?.data);
      setload(false);
      setData(data?.data);
    });
  };

  const deleteBook = () => {
    axios
      .post(`${config['baseUrl']}/deletebooks`, {
        id: deleteBookId,
      })
      .then((res) => {
        console.log('deleted successfully');
        setShowConfirmationModal(false);
        getAllData();
      });
  };

  const updateBookContent = () => {
    setIsLoading(true);
    if (language === 'english') {
      const formData = new FormData();
      formData.append('book_id', updatePage.book_id);
      formData.append('id', updatePage.id);
      formData.append('content', updatePage.content);
      if (updatePage?.is_image_change) {
        formData.append('file', updatePage?.image);
        setUpdatePage({ ...updatePage, is_image_change: false });
      }
      axios
        .post(`${config['baseUrl']}/updatebookscontent`, formData)
        .then((res) => {
          setIsLoading(false);
          UpdatetoggleModal(false);
          getAllPages(updatePage.book_id);
        })
        .catch((error) => {
          setIsLoading(false);
          console.log('error', error);
        });
    } else {
      const formData = new FormData();
      formData.append('translation', updatePage?.translation);
      if (updatePage?.is_image_change) {
        formData.append('file', updatePage?.image);
      }
      axios
        .put(
          `${config['baseUrl']}/bookPageTranslation/${updatePage?.id}`,
          formData
        )
        .then((res) => {
          setIsLoading(false);
          UpdatetoggleModal(false);
          getAllTranslatePages(updatePage.books_id, language);
        })
        .catch((error) => {
          setIsLoading(false);
          console.log('error', error);
        });
    }
  };
  const deleteBookContent = () => {
    setIsLoading2(true);
    axios
      .delete(
        `${config['baseUrl']}/books/${updatePage.book_id}/pages/${updatePage.id}`
      )
      .then((res) => {
        setIsLoading2(false);
        UpdatetoggleModal(false);
        getAllPages(updatePage.book_id);
      })
      .catch((error) => {
        setIsLoading2(false);
        console.log('error', error);
      });
  };

  const CreateBookContent = () => {
    const selectedLang = [];
    const entries = Object.entries(selectLanguages);
    for (const [key, value] of entries) {
      if (value) {
        selectedLang.push(key);
      }
    }
    setIsLoading(true);
    const formData = new FormData();
    const BookID = BookId;
    formData.append('book_id', BookId);
    formData.append('file', BookContentFile);
    formData.append('content', BookContent);
    selectedLang.forEach((lang) => {
      formData.append('translatedLanguages', lang);
    });
    axios
      .post(`${config['baseUrl']}/bookscontentInsert`, formData)
      .then((res) => {
        console.log(res.result);
        setBookId('');
        setBookContentFile('');
        setBookContent('');
        setIsLoading(false);
        CreateBooktoggleModal(false);
        getAllPages(BookID);
        setSelectLanguages({
          chinese: false,
          french: false,
          german: false,
          hindi: false,
          urdu: false,
        });
      })
      .catch((error) => {
        setIsLoading(false);
        console.log('error', error);
      });
    setSeconds(120);
  };

  const columns = [
    {
      name: 'R.No',
      cell: (row, index) => index + 1,
    },
    {
      name: 'Title',
      selector: (row) => <span>{row.title}</span>,
    },
    {
      name: 'Image',
      selector: (row) => (
        <img
          src={`${config['baseUrl']}${row.image}`}
          className='category-img'
        />
      ),
    },
    {
      name: 'Actions',
      cell: (row) => (
        <div className='actionsBtns'>
          <button
            onClick={() => {
              setDeleteBookId(row.id);
              setShowConfirmationModal(true);
            }}
            className='deleteBtn'>
            Delete Book
          </button>
          <button
            onClick={() => {
              setSelectedbookId(row?.id);
              setSelectedLanguageModal(true);
            }}
            className='updatePwdBtn'>
            Create Book's Content
          </button>
        </div>
      ),
    },
  ];

  const getAllPages = async (bookId) => {
    setBookId(bookId);
    axios
      .get(`${config['baseUrl']}/getbookscontent/${bookId}`)
      .then(({ data }) => {
        console.log(data);
        setShowAllPages(data.data);
        setShow(true);
      });
  };

  const getAllTranslatePages = (bookId, language = '') => {
    setShow(true);
    axios
      .get(`${config['baseUrl']}/bookPageTranslation/${bookId}/${language}`)
      .then(({ data }) => {
        setShowAllPages(data.data);
      });
  };

  const validateLanguage = (language) => {
    setShowAllPages([]);
    setLanguage(language);
    setSelectedLanguageModal(false);
    if (language === 'english') {
      getAllPages(selectedbookId);
    } else {
      getAllTranslatePages(selectedbookId, language);
    }
  };

  return (
    <>
      <div className='userListBox mt-5 mb-5'>
        <OrderDataTable columns={columns} data={data} />
      </div>
      {UpdatemodalShown && (
        <Modal
          show={UpdatemodalShown}
          onHide={() => UpdatetoggleModal(false)}
          size='lg'
          className='create-book-modal'
          centered>
          <Modal.Header closeButton>
            <Modal.Title>Update Book content</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>
              <div className='inneraddUserBox'>
                <div className='form-group'>
                  <CKEditor
                    editor={ClassicEditor}
                    data={updatePage?.content || updatePage?.translation || ''}
                    onChange={(event, editor) => {
                      const updatedData = editor?.getData() || '';
                      setUpdatePage((prev) =>
                        language === 'english'
                          ? { ...updatePage, content: updatedData }
                          : { ...updatePage, translation: updatedData }
                      );
                    }}
                  />
                </div>
              </div>
              <div className='inneraddUserBox'>
                <div className='form-group'>
                  {updatePage?.image && (
                    <div className='d-flex align-items-center uploaded-image'>
                      <p className='m-0'>Uploaded</p>
                      <RxCross2
                        size={20}
                        onClick={() => {
                          setUpdatePage({
                            ...updatePage,
                            image: '',
                            is_image_change: true,
                          });
                        }}
                      />
                    </div>
                  )}
                  {!updatePage?.image && (
                    <input
                      type='file'
                      accept='image/*'
                      className='input-file'
                      onChange={(e) =>
                        setUpdatePage({
                          ...updatePage,
                          image: e.target.files[0],
                          is_image_change: true,
                        })
                      }
                    />
                  )}
                </div>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <div className='addUserBtnBox btn-box'>
              {language === 'english' && (
                <button
                  className='btn-width'
                  onClick={() => deleteBookContent()}>
                  {isLoading2 ? (
                    <Spinner animation='border' size={'lg'} />
                  ) : (
                    'Delete'
                  )}
                </button>
              )}

              <button className='btn-width' onClick={() => updateBookContent()}>
                {isLoading ? (
                  <Spinner animation='border' size={'lg'} />
                ) : (
                  'Update'
                )}
              </button>
            </div>
          </Modal.Footer>
        </Modal>
      )}
      {CreateBookmodalShown && (
        <Modal
          show={CreateBookmodalShown}
          onHide={() => CreateBooktoggleModal(false)}
          size='lg'
          className='create-book-modal'
          centered>
          <Modal.Header closeButton>
            <Modal.Title>Create Book content</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>
              <div className='inneraddUserBox'>
                <div className='form-group'>
                  <label>Book Content</label>
                  <CKEditor
                    editor={ClassicEditor}
                    data={BookContent}
                    onChange={(event, editor) => {
                      setBookContent(editor.getData());
                    }}
                  />
                </div>
              </div>
              <div>
                <div>
                  <p className='select-trans-opt-text'>
                    Select the languages for translation
                  </p>
                </div>
                <div key={`inline-checkbox`} className='mb-3'>
                  <Form.Check
                    inline
                    label='Chinese'
                    value={selectLanguages.chinese}
                    type={'checkbox'}
                    onChange={() =>
                      setSelectLanguages({
                        ...selectLanguages,
                        chinese: !selectLanguages.chinese,
                      })
                    }
                  />
                  <Form.Check
                    inline
                    label='French'
                    value={selectLanguages.french}
                    type={'checkbox'}
                    onChange={() =>
                      setSelectLanguages({
                        ...selectLanguages,
                        french: !selectLanguages.french,
                      })
                    }
                  />
                  <Form.Check
                    inline
                    label='German'
                    value={selectLanguages.german}
                    type={'checkbox'}
                    onChange={() =>
                      setSelectLanguages({
                        ...selectLanguages,
                        german: !selectLanguages.german,
                      })
                    }
                  />
                  <Form.Check
                    inline
                    label='Hindi'
                    value={selectLanguages.hindi}
                    type={'checkbox'}
                    onChange={() =>
                      setSelectLanguages({
                        ...selectLanguages,
                        hindi: !selectLanguages.hindi,
                      })
                    }
                  />
                  <Form.Check
                    inline
                    label='Urdu'
                    value={selectLanguages.urdu}
                    type={'checkbox'}
                    onChange={() =>
                      setSelectLanguages({
                        ...selectLanguages,
                        urdu: !selectLanguages.urdu,
                      })
                    }
                  />
                </div>
              </div>
              <div className='inneraddUserBox'>
                <div className='form-group'>
                  <label>Book's Content image</label>
                  {selectLanguages.chinese}
                  <input
                    type='file'
                    accept='image/*'
                    onChange={(e) => setBookContentFile(e.target.files[0])}
                  />
                </div>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <div className='addUserBtnBox'>
              <button
                className='btn-width'
                onClick={() => CreateBookContent()}
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                }}>
                {isLoading ? (
                  <>
                    <p style={{ marginTop: '5px', marginRight: 10 }}>
                      {minutes < 10 ? `0${minutes}` : minutes}:
                      {remainingSeconds < 10
                        ? `0${remainingSeconds}`
                        : remainingSeconds}
                    </p>
                    <p>
                      <Spinner animation='border' size={'lg'} />
                    </p>
                  </>
                ) : (
                  'Create'
                )}
              </button>
            </div>
          </Modal.Footer>
        </Modal>
      )}

      {showConfirmationModal && (
        <Modal
          show={showConfirmationModal}
          onHide={() => setShowConfirmationModal(false)}
          centered>
          <Modal.Header closeButton>
            <Modal.Title>Confirmation</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p>Are you sure you want to delete this Book?</p>
          </Modal.Body>
          <Modal.Footer>
            <Button
              onClick={() => setShowConfirmationModal(false)}
              variant='secondary'>
              Close
            </Button>
            <Button onClick={deleteBook} variant='danger'>
              Delete
            </Button>
          </Modal.Footer>
        </Modal>
      )}
      {selectedLanguageModal && (
        <Modal
          show={selectedLanguageModal}
          onHide={() => setSelectedLanguageModal(false)}
          size='lg'
          className='language-selection-modal'
          centered>
          <Modal.Header closeButton>
            <Modal.Title>Confirmation</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p>Please select a language</p>
          </Modal.Body>
          <Modal.Footer>
            <Button
              onClick={() => validateLanguage('french')}
              variant='primary'>
              French
            </Button>
            <Button onClick={() => validateLanguage('hindi')} variant='primary'>
              Hindi
            </Button>
            <Button
              onClick={() => validateLanguage('english')}
              variant='primary'>
              English
            </Button>
            <Button
              onClick={() => validateLanguage('german')}
              variant='primary'>
              Germany
            </Button>
            <Button onClick={() => validateLanguage('urdu')} variant='primary'>
              Urdu
            </Button>
            <Button
              onClick={() => validateLanguage('chinese')}
              variant='primary'>
              Chinese
            </Button>
          </Modal.Footer>
        </Modal>
      )}

      {show && (
        <Modal
          show={show}
          onHide={() => setShow(false)}
          animation={false}
          size='lg'
          aria-labelledby='contained-modal-title-vcenter'
          backdrop='static'
          enforceFocus={false}
          centered>
          <Modal.Header closeButton>
            <Modal.Title>Book pages details</Modal.Title>
          </Modal.Header>
          <Modal.Body className='body-modal'>
            <div>
              {showAllPage.length === 0 && (
                <div className='not-found-container'>
                  <p>Add Stories in the Book</p>
                </div>
              )}
            </div>

            {showAllPage?.map((item, index) => {
              return (
                <div
                  key={index}
                  className='d-flex justify-content-between align-items-center mb-10 book-listing'
                  onClick={() => {
                    UpdatetoggleModal(true);
                    setUpdatePage(item);
                  }}>
                  <div className='container-text'>
                    <div style={{ position: 'relative', right: 20 }}>
                      {index + 1}
                    </div>

                    <div
                      className='text-modal-cat'
                      dangerouslySetInnerHTML={{
                        __html: item.content || item?.translation,
                      }}
                    />
                  </div>
                  <div className='image-container'>
                    <img
                      src={`${config['baseUrl']}${item.image}`}
                      className='image-show-modal'
                    />
                  </div>
                </div>
              );
            })}
          </Modal.Body>
          {language === 'english' && (
            <Modal.Footer>
              <Button
                variant='primary'
                onClick={() => CreateBooktoggleModal(true)}>
                Add Page
              </Button>
            </Modal.Footer>
          )}
        </Modal>
      )}
    </>
  );
};

export default CreateCategory;

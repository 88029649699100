import React, { useEffect, useState } from 'react'
import '../components/assets/css/userList.css'
import OrderDataTable from 'react-data-table-component'
import SearchBar from './SearchBar';
import useClipboard from "react-use-clipboard";
import { CSVLink } from "react-csv";
import AddUserModal from './modals/AddUserModal';
const config = require("../Helpers/config.json")
function UserListCom() {
    const [modalShown, toggleModal] = useState(false);
    const [load, setload] = useState(false)
    const [data, setData] = useState([])
    useEffect(() => {
        setload(true)
        fetch(`${config['baseUrl']}/getAllUsers`, {
            method: "GET",
            headers: { 'content-type': 'application/json' },
        }).then(res => {
            return res.json()
        }).then(ress => {
            setload(false)
            var temp
            temp = ress.data
            setData([...temp])
            console.log(data)
        })
    }, [])

    const [isCopied, setCopied] = useClipboard(JSON.stringify(data));
    const countPerPage = 5;

    const columns = [
        {
            name: "R.No",
            cell: (row, index) => index + 1
        },
        {
            name: "Name",
            selector: (row) => <span>{row.name}</span>

        },
        {
            name: "Email",
            selector: (row) => <span>{row.email}</span>
        },
        {
            name: "Number",
            selector: (row) => <span>{row.number}</span>
        },
        // {
        //     name : "Actions",
        //     cell : (row) => <div className='actionsBtns'>
        //         <button className='upadteBtn'>Update</button>
        //         <button className='deleteBtn'>Delete</button>
        //         <button className='updatePwdBtn'>Update Pwd!</button>
        //     </div>
        // }
    ]
    const addUser = () => {
        toggleModal(!modalShown);
    }
    return (
        <>
            <div className="userListBox mt-5 mb-5">
                <OrderDataTable
                    columns={columns}
                    data={data}
                    highlightOnHover
                />
            </div>
            <AddUserModal close={() => { toggleModal(false) }}
                {...{ modalShown, }} />
        </>
    )
}

export default UserListCom
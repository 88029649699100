import React, { useState } from 'react';
import './assets/css/addUserModal.css';
import axios from 'axios';
const config = require('../../Helpers/config.json');
function AddUserModal(props) {
  const [name, setname] = useState('');
  const [file, setFile] = useState('');
  const CreateCategory = () => {
    const formData = new FormData();
    formData.append('file', file);
    formData.append('name', name);
    axios
      .post(`${config['baseUrl']}/categoriesInsert`, formData)
      .then((res) => {
        if (res.message !== 'please upload the file') {
          window.location.reload(true);
        } else {
          console.log(res.message);
        }
      })
      .catch((error) => {
        console.log('error', error);
      });
  };
  return props.modalShown ? (
    <>
      <div className='addUserModalBox'>
        <div className='addUserBox'>
          <div>
            <div className='inneraddUserBox'>
              <div className='form-group'>
                <h4>Add New User!</h4>
                <label>Category name</label>
                <input
                  onChange={(e) => setname(e.target.value)}
                  type='text'
                  className='form-control'
                  placeholder='Name here!'
                  required
                />
              </div>
            </div>
            <div className='inneraddUserBox'>
              <div className='form-group'>
                <label>Category image</label>
                <input
                  type='file'
                  accept='image/*'
                  onChange={(e) => setFile(e.target.files[0])}
                />
              </div>
            </div>
            <div className='addUserBtnBox'>
              <button onClick={() => CreateCategory()}>Submit</button>
              <button
                onClick={() => {
                  props.close();
                }}>
                Cancel
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  ) : null;
}

export default AddUserModal;

import React from 'react'
import Routing from './Routing/routing'
function App() {
  return (
    <>
    <Routing/>
    </>
  );
}

export default App;

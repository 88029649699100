import React from 'react'
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Dashboard from '../pages/Dashboard'
// CSS ==========================
import '../assets/css/main.css'
// BOOTSTRAP ====================
import '../../node_modules/bootstrap/dist/css/bootstrap.min.css'
import AllOrders from '../pages/AllOrders';
import CustomersList from '../pages/CustomersList';
import DisputeOrder from '../pages/DisputeOrder';
import UsersList from '../pages/UsersList';
import Login from '../components/Login';
import Register from '../components/Register';
import UserStatistics from '../pages/UserStatistics';
import UsersList2 from '../pages/UsersList2';
import ShowServices from '../pages/ShowServices';
import ShowCreateCategory from '../pages/ShowCreateCategory';
import ShowCategory from '../pages/ShowCategory';
import ShowAllBooks from '../pages/ShowAllBooks';
function routing() {
  return (
    <>
      <Router>
        <Routes>
          <Route path='/' element={localStorage.getItem('token') !== null && localStorage.getItem('token') !== undefined && localStorage.getItem('token') !== "" ? <UsersList /> : <Login />} />
          {/* <Route path="Dashboard" element={<Dashboard />} /> */}
          <Route path="createCategory" element={<ShowCreateCategory />} />
          <Route path="UsersList" element={<UsersList />} />
          <Route path="category" element={<ShowCategory />} />
          <Route path="books" element={<ShowAllBooks />} />
        </Routes>
      </Router>
    </>
  )
}

export default routing